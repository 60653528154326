import { render, staticRenderFns } from "./ReverseMessage.vue?vue&type=template&id=9910bbfa&scoped=true&"
import script from "./ReverseMessage.vue?vue&type=script&lang=ts&"
export * from "./ReverseMessage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9910bbfa",
  null
  
)

export default component.exports