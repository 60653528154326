















import ProductTile from "../ProductTile.vue";

import Vue from "vue";
import Component from "vue-class-component";

import { Observer } from "mobx-vue";
import { HomeState } from "./viewState";
import { CartState } from "@/globalState/cartState";
@Observer
@Component({
  components: {
    ProductTile,
  }
})
export default class App extends Vue {
  state = new HomeState();
  cartState = CartState.get();
  mounted() {
    console.log(this.cartState)
  }
}
