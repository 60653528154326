
























































































import Vue from "vue";
import Component from "vue-class-component";
import styled from "vue-styled-components";

import { Observer } from "mobx-vue";
import { ProductDetailsState } from "./viewState";
import { CartState } from "@/globalState/cartState";
import { CurrentProductState } from "@/globalState/currentProductState";

const CardContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #e6ebf5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #2d2f33;
  display: flex;
  justify-content: center;
  align-items: top;
  padding: 80px 20px 20px 20px;
  max-width: 100%;
  width: 1500px;
  flex-direction: column;
  opacity: 0.8;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: row;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
`;
const CardLeft = styled.div`
  width: 50%;
  display: flex;
`;
const CardRight = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`;
const MultiImages = styled.div`
  display: flex;
  width: 22%;
  height: 100%;
  flex-direction: column;
`;
const QuantityValue = styled.div`
  font-weight: 700;
  letter-spacing: -0.02em;
  font-size: 1.5rem;
  font-family: BogleWeb, Helvetica Neue, Helvetica, Arial, sans-serif;
  padding: 40px 0 40px 0;
`;
const QuantitySize = styled.div`
  display: flex;
  height: 40px;
`;
const QuantityContainer = styled.label`
  display: flex;
  height: 100%;
  width: 100px;
  align-items: center;
  justify-content: center;
  border-style: solid;
  background-color: #ffffff;
  border-radius: 1px;
  border-width: 1px;
  font: white;
  margin-right: 6px;
`;
const QuantityVisible = styled.span`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const AddCart = styled.div`
  display: flex;
  height: 35px;
  padding-top: 10px;
`;
const ProductName = styled.div`
  font-weight: 600;
  letter-spacing: -0.02em;
  font-size: 1.5rem;
  font-family: BogleWeb, Helvetica Neue, Helvetica, Arial, sans-serif;
`;
const AddCartButtonContainer = styled.div`
  height: 80%;
  width: auto;
  align-items: center;
  justify-content: center;
  background-color: #0071dc;
  border-radius: 100px;
`;
const AddCartButton = styled.button`
  display: flex;
  height: 100%;
  width: 125px;
  align-items: center;
  justify-content: center;
  background-color: #0071dc;
  border-radius: 100px;
  color: white;
  margin-left: 10px;
  border-style: none;
`;
@Observer
@Component({
  components: {
    CardContainer,
    CardLeft,
    CardRight,
    MultiImages,
    QuantityValue,
    QuantitySize,
    QuantityContainer,
    QuantityVisible,
    AddCart,
    ProductName,
    AddCartButton,
    AddCartButtonContainer,
  },
})
export default class ProductDetails extends Vue {
  currentProductState = CurrentProductState.get();
  productDetails = this.currentProductState.currentProduct;
  state = new ProductDetailsState();
  cartState = CartState.get();
  addItemToCart(){ 
    if (this.cartState && this.productDetails){
      this.cartState.addItemToCart({
        image: this.productDetails.image,
        name: this.productDetails.name,
        price: this.state.quantities[this.state.picked].value,
        quantity: this.state.selectedQuantity,
        size: this.state.quantities[this.state.picked].size,
      })
      console.log(this.cartState)
      console.log(this.productDetails)
    }
  }              
  
}

// const Props = Vue.extend({
//   props: {
//     products: Array,
//   },
//   components: {
//     Tile,
//     ProductContainer,
//     ProductName,
//     ImageContainer,
//     StyledImage,
//     FreeShipping,
//   },
// });

// @Observer
// @Component
// export default class ProductTile extends Props {
//   state = new ProductDetailsState();
//   cartState = CartState.get();

//   productDetails = (this.$route as any).params;
// }
