





































































import Vue from "vue";
import Component from "vue-class-component";
import styled from "vue-styled-components";

import { Observer } from "mobx-vue";

import { CartState } from "@/globalState/cartState";

const CardContainer = styled.div`
  border-radius: 4px;
  border: 1px solid #e6ebf5;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #000;
  display: flex;
  align-items: top;
  padding: 80px 20px 20px 20px;
  max-width: 100%;
  width: 1100px;
  opacity: 0.8;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  flex-direction: column;
  font-family: BogleWeb,Helvetica Neue,Helvetica,Arial,sans-serif;

`;
const Cart = styled.div`
  display: flex;
  font-size: 1.7rem;

`;
const CardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardItemImage = styled.div`
  width: 162px;
  height: 118px;
`;
const CardItemLeft = styled.div`
  width: 36%;
  font-weight: 400;
  font-size: 1.25rem;
  padding: 0 37px 0 16px;
`;
const CardItemRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  flex: 1;
  font-size: 1rem;
  
`;
const ItemPrice = styled.div`
  font-weight: 600;
  font-size: 1.125rem;
`;
const CardItem = styled.div`
  display: flex;
  width: 100%;
  height: 140px;
  margin: 50px 0 50px 0;
`;

const ProductName = styled.div`
`;
const ProductSize = styled.div`
  color: #6d6e71;
  font-size: .95rem;
  line-height: 1.5;
  font-weight: 300;
`;
const ProductQuantity = styled.div`
  font-weight: 300;
  font-size: 0.85rem;
  line-height: 0.85rem;
  height: 20px;
  margin: 0 31px 0 31px;
  position: relative;
  top: -12.5px;
`;

const Remove = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: 20px;
  font-size: 0.9rem;
  font-weight: 400;
  text-decoration: underline;
`;
const CardItemParent = styled.div`
  display: flex;
  flex-direction: column;
`;
@Observer
@Component({
  components: {
    CardItemContainer,
    Cart,
    CardContainer,
    CardItemImage,
    CardItemLeft,
    CardItemRight,
    CardItem,
    ProductName,
    ProductSize,
    ProductQuantity,
    Remove,
    ItemPrice,
    CardItemParent,
  },
})
export default class ProductDetails extends Vue {
  cartState = CartState.get();
  mounted() {
      console.log(this.cartState)
  }
}

// const Props = Vue.extend({
//   props: {
//     products: Array,
//   },
//   components: {
//     Tile,
//     ProductContainer,
//     ProductName,
//     ImageContainer,
//     StyledImage,
//     FreeShipping,
//   },
// });

// @Observer
// @Component
// export default class ProductTile extends Props {
//   state = new ProductDetailsState();
//   cartState = CartState.get();

//   productDetails = (this.$route as any).params;
// }
