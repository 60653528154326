








import Vue from "vue";
import Component from "vue-class-component";
import { Observer } from "mobx-vue";
import { ReverseMessageState } from "./viewState";

@Observer
@Component
class App extends Vue {
  state = new ReverseMessageState();
  reverseMessage = () => {
    return this.state.input.split('').reverse().join('')
  }
}
export default App;
